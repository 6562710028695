<template>
  <div class="desktop-Carousel">
    <div class="desktop-content">
      <ui v-for="(item, index) in listDesktop" :key="item.id + index"
        ><li
          class="desktop-content-all"
          @click="detailsGoods(item,route.params.id)"
        >
          <p style="color: #fff">1</p>
          <p class="desktop-content-all-img">
            <img :src="item.pictures[0].img" alt="" />
          </p>
          <p class="desktop-content-all-t" :title="item.title">
            {{ item.productName }}
          </p>
          <p class="desktop-content-all-c" :title="item.text">
            {{ item.described }}
          </p>
          <p class="desktop-content-all-x">查看详情</p>
        </li>
      </ui>
    </div>
  </div>
</template>

<script setup>
import {onMounted, onUnmounted, ref, watchEffect} from "vue";
import { desktop } from "@/api/details";
import { useRoute,useRouter } from "vue-router";
import {baiduClue} from "@/api/shop";

const listDesktop = ref([]);
const route = useRoute();
const router = useRouter();
onMounted(()=>{
	// 百度埋点
	const url = window.location.href;
	if(url.search("bd_vid") != -1) {
		const bd_vid = url.match(/bd_vid=(\S*)/)[1];
		sessionStorage.setItem("bd_vid", bd_vid)
	}
})
onUnmounted(()=>{
	if(sessionStorage.getItem("bd_vid")) {
		sessionStorage.removeItem("bd_vid")
	}
})

const detailsGoods = (k,id) => {
	// 百度埋点
	const type = ref();
	switch(k.machineType){
		case 4: // 工作站
			type.value = 52;
			break;
		case 2: // 台式机
			type.value = 57;
			break;
		case 9: // 服务器
			type.value = 72;
      break;
	}
	if(sessionStorage.getItem("bd_vid")){
		let buriedData = [
			{
				logidUrl: window.location.href,
				newType: type.value,
			}
		]
		baiduClue(buriedData)
	}
  event.stopPropagation();
  if(k.machineType == 3 || k.machineType == 5 || k.machineType == 6){
    //router.push("/detailsGoodsNew/"+ k.id + "/1");
	  router.push({
		  name: "detailsGoodsNew",
		  query: {
			  id: k.id,
			  houseNo: 1
		  }
	  });
  }else{
    //router.push("/detailsGoods/"+ k.id + "/1");
	  router.push({
		  name: "detailsGoods",
		  query: {
			  id: k.id,
			  houseNo: 1
		  }
	  });
  }
};
const getDesktop = async (machineType = route.params.id) => {
  try {
    const res = await desktop(machineType);
    listDesktop.value = res.data;
    console.log(listDesktop.value, "listDesktop.value");
  } catch (error) {
    console.log("当前错误是:" + error + "!");
  }
};
watchEffect(() => getDesktop());
</script>

<style lang="less" scoped>
.desktop-Carousel {
  width: 1680px;
  margin: 0 auto;
  padding-bottom: 40px;
  .desktop-content {
    width: 1680px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 34px;
    // justify-content: space-between;
    .desktop-content-all {
      width: 399px;
      height: 500px;
      background: #ffffff;
      margin-top: 21px;
      margin-right: 21px;
      .hoverShadow ();
      .desktop-content-all-img {
        width: 240px;
        height: 240px;
        margin: 25px 90px 36px 80px;
        cursor: pointer;
        img {
          display: inline-block;
          width: 240px;
          height: 240px;
        }
      }
      .desktop-content-all-t {
        width: 293px;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        margin: 0 auto;
        margin-bottom: 13px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .desktop-content-all-c {
        width: 293px;
        height: 38px;
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
        margin-bottom: 23px;
        margin: 0 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .desktop-content-all-x {
        width: 180px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #ce1200;
        border-radius: 5px;
        text-align: center;
        margin: 0 auto;
        margin-top: 15px;
        font-size: 13px;
        font-weight: 400;
        color: #ce1200;
        cursor: pointer;
      }
      .desktop-content-all-x:hover {
        width: 180px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #ce1200;
        border-radius: 5px;
        text-align: center;
        margin: 0 auto;
        margin-top: 15px;
        font-size: 13px;
        font-weight: 400;
        background-color: #ce1200;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
</style>
